.f2-all{
    background-color: rgb(23, 25, 33);
    padding: 10px;
}

.social1-all{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rights1 {
    color:white;
    
}
.rights1 h4{
    padding-top: 5px;
    text-align: center;
   font-size: 15px;
}