
.left2{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.ss-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 0;
}
.s-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0;
  
}

/* .s-title{
    background-image: url('https://wallpapercave.com/wp/wp8414151.png');
} */

.s-title h1{
    
    font-size: 40px;
    color: rgb(1, 1, 1);
    text-align: center;
    margin-top: 30px;
}
   

.left{
    
}

.left1 h1{
    margin-top: 10px;
    font-size: 30px;
    font-family: cursive;
    color: rgb(37, 37, 143);
    
}



.left1 h4{
    font-size: 15px;
    text-align: justify;
    max-width: 350px;
    margin-top: -20px;
}

.left1{
    margin-left: 0px;
}

.left1 img{
    width: 350px;
    height: 250px;
    
}

.right1 img{
    width: 350px;
    height: 250px;
    
}
.right1 h4{
    text-align: justify;
    font-size: 15px;
    max-width: 350px;
    margin-top: -20px;
}


  .s-hr{
    width: 40%;
  }

.right1 h1{
    font-family: cursive;
    font-size: 30px;
    color: rgb(37, 37, 143);
}


@media (max-width: 657px) {
  

    .s-container{
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        align-items: center;
        margin-left: -200px;
    }

    .left1{
        order:2;
        margin-left: 220px;
        margin-right: 10px;
    }

    .right1{
        order: 1;
        margin-left: 220px;
        margin-right: 10px;
    }

    .s-hr{
        width: 80%;
        margin-left: 0px;
    }
     .middle { 
        order: 3;
     }

     .middle img{
        margin-left: 200px;
     }
}