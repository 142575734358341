.co-all{
    background-image: url('https://th.bing.com/th/id/OIP.JCQR14Shn-vSE5Bq5GqL0QHaE7?w=260&h=180&c=7&r=0&o=5&pid=1.7');

    min-height: 600px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.co1-all{
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 500px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    
}

.contact-container {
    border: 1px solid #fff; 
    border-radius: 0.5rem;
    width: 40px;
    
   
    
  }


  @media only screen and (max-width: 767px) {
.co-all{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: -30px;
    
}


.co1-all{
    width: 350px;
}


  }