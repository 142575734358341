.social-all{
    display: flex;
    flex-direction: row;
  
}

.f-left h6{
    color: grey;
}


.f-left{
    margin-top: 27px;
    margin-left: 140px;
}


.f-right{
    margin-left: -100px;
}

.f1-all{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: rgb(248, 249, 250);
}


.logo1 img{
    height: 100px;
    width: 100px;
}



@media only screen and (max-width: 767px) {
.f1-all{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.f-left{
    margin-left: 20px;
}









}
